export const dsApi = {
  connectDs: '/datasource/connectds',
  addDs: '/datasource/addds',
  getDsList: '/datasource/listds',
  getDsListForManage: '/datasource/listdsformanage',
  listDsForManage: '/datasource/listdsformanage',
  getDsConfig: '/datasource/querydsconfig',
  updateDsConfig: '/datasource/updatedsconfig',
  getDs: '/datasource/queryds',
  updateDsHosts: '/datasource/updatedshosts',
  updateDsAccount: '/datasource/updatedsaccount',
  deleteDs: '/datasource/deleteds',
  updateDsDesc: '/datasource/updatedsdesc',
  modifyUserDsAuth: '/dataauth/modifyuserdsauth',
  listUserDsAuth: '/dataauth/listuserdsauth',
  listUserAllDsAuth: '/dataauth/listuseralldsauth',
  listAllDsAuthKind: '/dataauth/listalldsauthkind',
  updateDsBindingAndEnv: '/datasource/updatedsbindingandenv',
  schemaCheckDdl: '/dataauth/check_permissions'
};
