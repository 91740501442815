<template>
  <div class="cc-cluster-type-select">
    <a-button v-for="env in deployEnvList" :key="env.value"
            :type="env.value === selectedClusterEnv ? 'primary': 'default'"
            style="width:120px"
            @click="handleSelectClusterEnv(env.value)">
      {{ env.name }}
    </a-button>
  </div>
</template>

<script>
import { CLUSTER_ENV } from '@/consts';

export default {
  name: 'CCClusterTypeSelect',
  model: {
    prop: '__selected_cluster_env__',
    event: '__handle_select_cluster_env__'
  },
  props: {
    __selected_cluster_env__: String,
    deployEnvList: Array
  },
  data() {
    return {
      selectedClusterEnv: ''
    };
  },
  created() {
    this.selectedClusterEnv = this.__selected_cluster_env__ || CLUSTER_ENV.ALIBABA_CLOUD_HOSTED;
  },
  methods: {
    handleSelectClusterEnv(env) {
      this.selectedClusterEnv = env;
    }
  },
  watch: {
    selectedClusterEnv(value) {
      this.$emit('__handle_select_cluster_env__', value);
    }
  }
};
</script>

<style scoped>

</style>
