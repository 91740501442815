import { userApi } from '@/services/http/api/user';
import { roleApi } from '@/services/http/api/role';
import { clusterApi } from '@/services/http/api/cluster';
import { commonApi } from '@/services/http/api/common';
import { workerApi } from '@/services/http/api/worker';
import { queryApi } from '@/services/http/api/query';
import { dsApi } from '@services/http/api/ds';
import { envApi } from '@/services/http/api/env';
import { schemaApi } from '@/services/http/api/schema';
import { ticketApi } from '@services/http/api/ticket';
import { aliyunApi } from '@services/http/api/aliyun';
import { consolejobApi } from '@services/http/api/consoleJob';
import { ruleApi } from '@services/http/api/rule';
import { dataCodeApi } from '@services/http/api/dataCode';
import { desensitizationApi } from '@services/http/api/desensitization';
import { dataRuleApi } from '@services/http/api/dataRule';
import { approTemplatesApi } from '@services/http/api/approTemplates';
import { redisApi } from '@services/http/api/redis';
import { exportApi } from './export';

export const api = {
  ...userApi,
  ...roleApi,
  ...clusterApi,
  ...commonApi,
  ...workerApi,
  ...queryApi,
  ...dsApi,
  ...envApi,
  ...schemaApi,
  ...ticketApi,
  ...aliyunApi,
  ...consolejobApi,
  ...ruleApi,
  ...dataCodeApi,
  ...desensitizationApi,
  ...dataRuleApi,
  ...approTemplatesApi,
  ...exportApi,
  ...redisApi
};
