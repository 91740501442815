import './iview.config';
import './antd.config';

import Vue from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import CCIconfont from '@components/widgets/CCIconfont.vue';
import CCLabel from '@components/widgets/CCLabel.vue';
import CCRegionSelect from '@components/widgets/CCRegionSelect.vue';
import CCClusterTypeSelect from '@components/widgets/CCClusterTypeSelect.vue';
import CCStatus from '@components/widgets/CCStatus.vue';
import CCDataSourceIcon from '@components/widgets/CCDataSourceIcon.vue';
import CCSmsButton from '@components/widgets/CCSmsButton.vue';
import CCPasswordInput from '@components/widgets/CCPasswordInput.vue';
import CCSchemaTreeSelect from '@components/widgets/CCSchemaTreeSelect.vue';
import CCAuthTreeSelect from '@components/widgets/CCAuthTreeSelect.vue';
import CCTableTreeSelect from '@components/widgets/CCTableTreeSelect.vue';
import CCFilterSelect from '@components/widgets/CCFilterSelect.vue';
import CCPagination from './widgets/CCPagination.vue';

Vue.component(VueCountdown.name, VueCountdown);

Vue.component('cc-iconfont', CCIconfont);
Vue.component('cc-label', CCLabel);
Vue.component('cc-region-select', CCRegionSelect);
Vue.component('cc-cluster-type-select', CCClusterTypeSelect);
Vue.component('cc-status', CCStatus);
Vue.component('cc-data-source-icon', CCDataSourceIcon);
Vue.component('cc-sms-button', CCSmsButton);
Vue.component('cc-password-input', CCPasswordInput);
Vue.component('cc-schema-tree-select', CCSchemaTreeSelect);
Vue.component('cc-auth-tree-select', CCAuthTreeSelect);
Vue.component('cc-table-tree-select', CCTableTreeSelect);
Vue.component('cc-filter-select', CCFilterSelect);
Vue.component('cc-pagination', CCPagination);
