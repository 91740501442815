export const ticketApi = {
  listTickets: '/ticket/listbasic',
  createTicket: '/ticket/create',
  deleteTicket: '/ticket/delete',
  updateApproKey: '/ticket/appro/updatekey',
  listApproTemplates: '/ticket/appro/listtemplates',
  queryTicketsDetail: '/ticket/querydetail',
  cancelTicket: '/ticket/cancel',
  confirmTicket: '/ticket/confirm',
  retryTicket: '/ticket/retry',
  closeTicket: '/ticket/close',
  checkPermissions: '/ticket/check_permissions'
};
