export const aliyunApi = {
  getEcsList: '/aliyun/ecs/listecs',
  addEcs: '/aliyun/ecs/ecsaddandinstall',
  getRdsList: '/aliyun/rds/listrds',
  querynetinfo: '/aliyun/rds/querynetinfo',
  installAliyunEcs: '/aliyun/ecs/install',
  uninstallAliyunEcs: '/aliyun/ecs/uninstall',
  upgradeAliyunEcs: '/aliyun/ecs/upgradeall',
  checkCloudDsAccount: 'aliyun/rds/checkclouddsaccount',
  queryWhiteList: '/aliyun/rds/querywhitelist',
  addClusterWhiteList: '/aliyun/rds/addclusterwhitelist'
};
