import Vue from 'vue';
// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
import eventBus from '@utils/eventBus';
// import config from '../package.json';
import VXETable from 'vxe-table';
import App from './App.vue';
import router from './router';
import store from './store';
import '@components';
import '@directives';
import '@services';
import '@filters';

import './styles/reset.css';
import './styles/iconfont.css';
import 'view-design/dist/styles/iview.css';
import './styles/global.less';
import './styles/iconfont';

import 'xe-utils';
import 'vxe-table/lib/style.css';

import './utils/vxeTableTemplates';

Vue.use(VXETable);
// const isDev = process.env.NODE_ENV === 'development';

// if (!isDev) {
//   const {
//     VUE_APP_SENTRY_DSN,
//     VUE_APP_ENV
//   } = process.env;
//   Sentry.init({
//     Vue,
//     dsn: VUE_APP_SENTRY_DSN,
//     release: `${VUE_APP_ENV}-${config.version}`,
//     environment: VUE_APP_ENV,
//     autoSessionTracking: true,
//     integrations: [
//       new Integrations.BrowserTracing()
//     ],
//
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0
//   });
// }

Vue.config.productionTip = false;

Vue.use(eventBus);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
