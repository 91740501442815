export const queryApi = {
  createSession: '/query/createsession',
  cancelQuery: '/query/cancel',
  closesSession: '/query/closesession',
  commit: 'query/commit',
  executeQuery: 'query/execute',
  getAutoCommit: '/query/getautocommit',
  rollBack: '/query/rollback',
  analysisSplit: '/query/analysissplit',
  changeSchema: '/query/changeschema',
  setAutocommit: '/query/setautocommit',
  setTx: '/query/set_tx'
};
