import axios from 'axios';
import { Message } from 'view-design';
import { Modal } from 'ant-design-vue';
import { api } from '@services/http/api';
import { checkStatus } from './utils';
import Router from '../../router';

let authPreMethod = '';

const instance = axios.create({
  timeout: 60000,
  transformRequest: [(data) => {
    if (!data) {
      return;
    }
    if (Object.prototype.toString.call(data) !== '[object FormData]') {
      Object.keys(data)
        .map((key) => {
          if (!data[key] && data[key] !== false && data[key] !== 0) {
            data[key] = null;
          }
          return null;
        });
      return JSON.stringify(data);
    }
    return data;
  }]
});

const noTransformInstance = axios.create({
  timeout: 60000
});

instance.defaults.withCredentials = true;
noTransformInstance.defaults.withCredentials = true;

instance.interceptors.request.use((config) => {
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    ...config.headers
  };
  return Promise.resolve(config);
}, (error) => {
  Promise.reject(error);
});

noTransformInstance.interceptors.request.use((config) => {
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    ...config.headers
  };

  return Promise.resolve(config);
}, (error) => {
  Promise.reject(error);
});

instance.interceptors.response.use((res) => Promise.resolve(checkStatus(res)), (error) => {
  if (error.response) {
    return Promise.reject(checkStatus(error.response));
  }
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    return Promise.reject(new Error('请求超时'));
  }
  return Promise.reject(new Error(error));
});

noTransformInstance.interceptors.response.use((res) => Promise.resolve(checkStatus(res)), (error) => {
  if (error.response) {
    return Promise.reject(checkStatus(error.response));
  }
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    return Promise.reject(new Error('请求超时'));
  }
  return Promise.reject(new Error(error));
});

const request = async (opt) => {
  const options = {
    method: 'post',
    ...opt
  };

  const {
    url: requestUrl,
    msg,
    modal = true,
    page
  } = options;

  try {
    if (!['/login', '/register', '/logout', '/global_settings', '/list_org', '/login_supplement', '/check_supplement', '/load_supplement_info'].includes(options.url)) {
      options.baseURL = process.env.VUE_APP_BASE_URL;
    }

    const res = ['/datasource/addds'].includes(requestUrl) ? await noTransformInstance(options) : await instance(options);
    if (res.success && requestUrl === api.verifyOpPwd) {
      window.$bus.emit(authPreMethod);
      authPreMethod = '';
    }

    if (!opt.noStatus) {
      if (!res.success) {
        switch (res.code) {
          case '2011':
            window.$bus.emit('setCloudAKSKModal');
            break;
          case '6001':
            window.$bus.emit('dingDingSettingModal');
            break;
          default:
            if (['/login', '/datasource/connectds'].includes(requestUrl)) {
              return res;
            }
            if (modal) {
              Modal.error({
                title: '错误',
                class: 'limit-height',
                width: 500,
                okText: '确定',
                zIndex: 9999,
                content: res.msg ? (res.msg.startsWith('[') && res.msg.endsWith(']') ? JSON.parse(res.msg)[0] : res.msg) : '系统异常,请联系管理员'
              });
            }
        }
      } else if (res.success && msg) {
        Message.success(options.msg);
      }
    }
    return res;
  } catch (err) {
    switch (err.status) {
      case 401:
        await Router.push({ name: 'Login' });
        break;
      case 498:
        window.$bus.emit('setOpPasswordModal');
        break;
      case 499:
        if (requestUrl === api.listSchemaFirstLevel) {
          authPreMethod = `${page}_listfirstlevel_callback`;
        }
        if (requestUrl === api.createSession) {
          authPreMethod = `${page}_listfirstlevel_callback`;
        }
        window.$bus.emit('showEnterOpPwdModal');
        break;
      case 406:
        Modal.error({
          title: '权限异常',
          content: err.msg || '您没有该权限的操作，请联系主账号或管理员'
        });
        break;
      default:
        Modal.error({
          title: 'Error',
          content: err.msg || '系统异常'
        });
    }
    return err;
  }
};

export default request;
