import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */'@views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */'@views/Register.vue')
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import(/* webpackChunkName: "reset" */'@views/ResetPwd.vue')
  },
  {
    path: '/',
    name: 'Home',
    redirect: 'sql',
    component: () => import(/* webpackChunkName: "login" */'@views/Home.vue'),
    children: [
      {
        path: 'sql',
        name: 'SQL',
        component: () => import(/* webpackChunkName: "sql" */'@views/sql/Index.vue')
      },
      {
        path: 'ticket',
        name: 'Ticket',
        component: () => import(/* webpackChunkName: "ticket" */'@views/ticket/Index.vue')
      },
      {
        path: '/ticket/:id',
        name: 'Ticket/id',
        component: () => import(/* webpackChunkName: "ticket" */'../views/ticket/TicketDetail.vue')
      },
      {
        path: '/ticket_create',
        name: 'Ticket_create',
        component: () => import(/* webpackChunkName: "ticket" */'../views/ticket/Ticket.vue')
      },
      {
        path: 'system',
        name: 'System',
        component: () => import(/* webpackChunkName: "system" */'@views/system/Index.vue'),
        children: [
          {
            path: 'info_center',
            name: 'InfoCenter',
            component: () => import(/* webpackChunkName: "ticket" */'@views/consoleJob/Index.vue')
          },
          {
            path: 'console_job/:id',
            name: 'ConsoleJob/id',
            component: () => import(/* webpackChunkName: "ticket" */'@views/consoleJob/ConsoleJobDetail.vue')
          },
          {
            path: 'async_job_list',
            name: 'ASYNC_JOB_LIST',
            component: () => import(/* webpackChunkName: "async-job-list" */'@views/system/AsyncJobList/AsyncJobList.vue')
          },
          {
            path: 'async_job/:id',
            name: 'ASYNC_JOB_DETAIL',
            component: () => import(/* webpackChunkName: "async-job-list" */'@views/system/AsyncJobList/AsyncJobDetail.vue')
          },
          {
            path: '',
            name: 'System_Home',
            component: () => import(/* webpackChunkName: "system-home" */'@views/system/Home.vue')
          },
          {
            path: 'user/config',
            name: 'User_Config',
            component: () => import(/* webpackChunkName: "system-home" */'@views/system/user/UserConfig.vue')
          },
          {
            path: 'role',
            name: 'System_Role',
            component: () => import(/* webpackChunkName: "system-role" */'@views/system/role/Index.vue')
          },
          {
            path: 'machine',
            name: 'System_Machine',
            component: () => import(/* webpackChunkName: "system-cluster" */'@views/system/cluster/Index.vue')
          },
          {
            path: 'machine/list/:clusterId',
            name: 'System_Machine_List',
            component: () => import(/* webpackChunkName: "system-cluster-list" */'@views/system/cluster/WorkerList.vue')
          },
          {
            path: 'datasource',
            name: 'System_DataSource',
            component: () => import(/* webpackChunkName: "system-datasource" */'@views/system/datasource/Index.vue')
          },
          {
            path: 'datasource/params/:id',
            name: 'System_DataSource_Params/id',
            component: () => import(/* webpackChunkName: "system-datasource" */'@views/system/datasource/Params.vue')
          },
          {
            path: 'datasource/add',
            name: 'System_DataSource_Add',
            component: () => import(/* webpackChunkName: "system-datasource" */'@views/system/datasource/steps/Index.vue')
          },
          {
            path: 'sub_account',
            name: 'System_Sub_Account',
            component: () => import(/* webpackChunkName: "system-subaccount" */'@views/system/subaccount/Subaccount.vue')
          },
          {
            path: 'sub_account/auth/:uid',
            name: 'System_Sub_Account_Auth',
            component: () => import(/* webpackChunkName: "system-subaccount-auth" */'@views/system/subaccount/Auth.vue')
          },
          {
            path: 'env',
            name: 'System_Env',
            component: () => import(/* webpackChunkName: "system-env" */'@views/system/Env.vue')
          },
          {
            path: 'rules',
            name: 'System_Rule',
            component: () => import(/* webpackChunkName: "system-env" */'@views/system/rule/Rules.vue')
          },
          {
            path: 'desensitization',
            name: 'System_Desensitization',
            component: () => import(/* webpackChunkName: "system-desensitization" */'@views/system/desensitization/Desensitization.vue')
          },
          {
            path: 'desensitization/add',
            name: 'System_Desensitization_Add',
            component: () => import(/* webpackChunkName: "system-desensitization" */'@views/system/desensitization/AddDesensitization.vue')
          },
          {
            path: 'data_rules',
            name: 'System_Data_Rules',
            component: () => import(/* webpackChunkName: "system-data-rules" */'@views/system/dataRule/DataRule.vue')
          },
          {
            path: 'data_rules/add',
            name: 'System_Data_Rules_Add',
            component: () => import(/* webpackChunkName: "system-data-rules-add" */'@views/system/dataRule/AddDataRule.vue')
          },
          {
            path: 'data_code',
            name: 'System_Data_Code',
            component: () => import(/* webpackChunkName: "system-env" */'@views/system/dataCode/DataCode.vue')
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router;
