export const workerApi = {
  createWorker: '/worker/createinitialworker',
  getWorkerList: '/worker/listworkers',
  getDownloadUrl: '/worker/downloadclienturl',
  deleteWorker: '/worker/deleteworker',
  startWorker: '/worker/waittoonline',
  stopWorker: '/worker/waittooffline',
  getWorkerConfig: '/worker/clientcoreconfig',
  updateWorkerDesc: '/worker/updateworkerdesc'
};
