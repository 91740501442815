export const exportApi = {
  submitExportJob: '/export/submitexportjob',
  listExportJobs: '/export/listexportjobs',
  queryExportJob: '/export/queryexportjob',
  retryExportTask: '/export/retryexporttask',
  skipExportTask: '/export/skipexporttask',
  cancelExportJob: '/export/cancelexportjob',
  listExportJoBbyBiz: '/export/listexportjobbybiz',
  genDownloadInfo: '/export/gendownloadinfo',
  updateExportTaskLimit: '/export/updateexporttasklimit'
};
