import {
  UPDATE_CLUSTER_LIST,
  UPDATE_DEPLOY_ENV_LIST_MAP,
  UPDATE_DS_TYPE_LIST,
  UPDATE_REGION_LIST_MAP,
  UPDATE_USERINFO
} from '@/store/mutationTypes';

export default {
  [UPDATE_USERINFO](state, userInfo) {
    if (userInfo.auth) {
      const menuItems = new Set();
      const authArr = [];
      let showMessage = false;
      userInfo.auth.forEach((data) => {
        authArr.push(data.labelValue);
        if (data.labelValue === 'SYSTEM_GET_RESOURCE_LIMIT' || data.labelValue === 'SYSTEM_GET_DS_OP_AUDITS') {
          menuItems.add('/system');
        }

        if (data.firstClassifyName === '机器管理') {
          menuItems.add('/system/machine');
        }

        if (data.firstClassifyName === '数据源管理') {
          menuItems.add('/system/datasource');
        }

        if (data.secondClassifyName === '用户管理权限') {
          menuItems.add('/system/sub_account');
        }

        if (data.secondClassifyName === '数据源环境管理') {
          menuItems.add('/system/env');
        }

        if (data.secondClassifyName === '角色管理相关权限') {
          menuItems.add('/system/role');
        }

        if (data.firstClassifyName === '规则校验管理') {
          menuItems.add('/system/rules');
        }

        if (data.firstClassifyName === '数据脱敏管理') {
          menuItems.add('/system/desensitization');
        }

        if (data.secondClassifyName === '数据处理自定义包管理') {
          menuItems.add('/system/data_code');
        }

        if (data.secondClassifyName === '数据处理规则管理') {
          menuItems.add('/system/data_rules');
        }

        if (data.secondClassifyName === '控制台异步任务管理权限') {
          menuItems.add('/system/console_job');
        }

        if (data.secondClassifyName === '异步执行管理') {
          menuItems.add('/system/async_job_list');
        }

        if (data.labelValue === 'SYSTEM_CONSOLE_JOB_LIST_LAST_FIVE_FAILED') {
          showMessage = true;
        }

        this.menuItems = Array.from(menuItems);
      });
      const orders = ['/system', '/system/machine', '/system/datasource', '/system/sub_account',
        '/system/env', '/system/role', '/system/rules', '/system/desensitization',
        '/system/data_rules', '/system/data_code', '/system/console_job', '/system/async_job_list'];
      userInfo.menuItems = Array.from(menuItems)
        .sort((a, b) => orders.indexOf(a) - orders.indexOf(b));
      userInfo.authArr = authArr;
      userInfo.showMessage = showMessage;
    }

    state.userInfo = userInfo;
  },
  [UPDATE_CLUSTER_LIST](state, list) {
    const temp = {};
    let workersNum = 0;
    let runningWorkersNum = 0;
    list.forEach((cluster) => {
      workersNum += cluster.workerCount;
      runningWorkersNum += cluster.runningCount;
      temp[cluster.id] = {
        name: cluster.clusterName,
        desc: cluster.clusterDesc,
        value: cluster.id,
        ...cluster
      };
    });

    state.clusterListMap = temp;
    state.allClusterWorkers = workersNum;
    state.allClusterRunningWorkers = runningWorkersNum;
  },
  [UPDATE_DEPLOY_ENV_LIST_MAP](state, list) {
    const temp = {};
    list.forEach((env) => {
      temp[env.name] = {
        name: env.nameI18n,
        value: env.name
      };
    });

    state.deployEnvListMap = temp;
  },
  [UPDATE_REGION_LIST_MAP](state, list) {
    const temp = {};
    const temp2 = {};
    const temp3 = {};
    list.aliyun.forEach((region) => {
      const { regionName, regionNameI18n, regionKindI18n } = region;
      temp3[regionName] = regionNameI18n;
      if (temp[regionKindI18n]) {
        temp[regionKindI18n].children.push(region);
      } else {
        temp[regionKindI18n] = {
          name: regionKindI18n,
          children: [region]
        };
      }
    });
    list.self.forEach((region) => {
      const { regionKindI18n } = region;
      if (temp2[regionKindI18n]) {
        temp2[regionKindI18n].children.push(region);
      } else {
        temp2[regionKindI18n] = {
          name: regionKindI18n,
          children: [region]
        };
      }
    });
    state.aliyunRegionListMap = temp;
    state.selfRegionListMap = temp2;
    state.regionList = temp3;
  },
  [UPDATE_DS_TYPE_LIST](state, list) {
    state.dsTypeList = list;
  }
};
