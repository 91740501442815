export const desensitizationApi = {
  addDesensitizeRule: '/datadesensitizerule/adddesensitizerule',
  deleteDesensitizeRule: '/datadesensitizerule/deletedesensitizerule',
  listDesensitizeRules: '/datadesensitizerule/listdesensitizerules',
  modifyDesensitizeRule: '/datadesensitizerule/modifydesensitizerule',
  updateDesensitizeRule: '/datadesensitizerule/updatedesensitizerule',
  updateDesensitizeRuleAbility: '/datadesensitizerule/updatedesensitizeruleability',
  desensitizeRuleSample: '/datadesensitizerule/desensitizerulesample',
  istTableDesensitizeRules: '/datadesensitizerule/listtabledesensitizerules',
  desensitizeRuleTest: '/datadesensitizerule/desensitizeruletest'
};
