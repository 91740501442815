// export const REGIONS = [
//   {
//     name: '中国',
//     children: ['杭州', '上海', '北京', '深圳', '青岛', '张家口', '呼和浩特']
//   },
//   {
//     name: '亚太地区',
//     children: ['新加坡']
//   },
//   {
//     name: '欧美地区',
//     children: ['硅谷', '伦敦']
//   }
// ];
export const CLUSTER_ENV = {
  SELF_MAINTENANCE: 'SELF_MAINTENANCE',
  ALIBABA_CLOUD_HOSTED: 'ALIBABA_CLOUD_HOSTED'
};

export const CLUSTER_TYPE = {
  SELF_MAINTENANCE: {
    name: '自建机房',
    value: 'self'
  },
  ALIBABA_CLOUD_HOSTED: {
    name: '阿里云',
    value: 'aliyun'
  }
};

export const STATUS_COLOR = {
  success: '#52C41A',
  warning: '#FFA30E',
  error: '#FF1815'
};

export const VERIFY_TYPE = {
  SMS: 'SMS_VERIFY_CODE'
};

export const VERIFY_CODE_TYPE = {
  REGISTER: 'REGISTER',
  RESET_PASSWORD: 'RESET_PASSWORD',
  FETCH_WORKER_DEPLOY_CORE_CONFIG: 'FETCH_WORKER_DEPLOY_CORE_CONFIG',
  RESET_OP_PASSWORD: 'RESET_OP_PASSWORD'
};

export const ACCOUNT_TYPE = {
  PRIMARY_ACCOUNT: 'PRIMARY_ACCOUNT',
  SUB_ACCOUNT: 'SUB_ACCOUNT',
  LDAP_ACCOUNT: 'LDAP_ACCOUNT'
};

export const LOGIN_TYPE = {
  LOGIN_PASSWORD: 'LOGIN_PASSWORD',
  LOGIN_VERIFY: 'LOGIN_VERIFY',
  LOGIN_LDAP: 'LOGIN_LDAP'
};

export const TICKET_STATUS = {
  WAIT_APPROVAL: '等待审批',
  WAIT_CONFIRM: '等待确认',
  WAIT_EXEC: '等待执行',
  RUNNING: '执行中',
  REJECTED: '已拒绝',
  EXEC_FAIL: '执行失败',
  FINISHED: '执行成功',
  CANCELING: '撤销中',
  CANCELED: '已撤销',
  CLOSED: '已关闭'
};

export const TICKET_STATUS_COLOR = {
  WAIT_APPROVAL: '#FFA30E',
  WAIT_CONFIRM: '#FFA30E',
  WAIT_EXEC: '#FFA30E',
  RUNNING: '#FFA30E',
  REJECTED: '#FF1815',
  EXEC_FAIL: '#FF6E0D',
  FINISHED: '#52C41A',
  CANCELING: '#FFA30E',
  CANCELED: '#999999',
  CLOSED: '#999999'
};

export const HEALTH_LEVEL_COLOR = {
  SubHealth: 'warning',
  Health: 'success',
  Unhealthy: 'error'
};

export const EXPORT_HEALTH_LEVEL_COLOR = {
  INIT: 'success',
  WAIT_TO_START: 'success',
  RUNNING: 'success',
  WAIT_TO_CANCEL: 'success',
  CANCEL: 'success',
  WAIT_TO_FAIL: 'error',
  FAIL: 'error',
  COMPLETE: 'success'
};

export const WORKER_STATE = {
  OFFLINE: {
    name: '离线',
    value: 'OFFLINE'
  },
  WAIT_TO_ONLINE: {
    name: '等待上线',
    value: 'WAIT_TO_ONLINE'
  },
  ABNORMAL: {
    name: '异常',
    value: 'ABNORMAL'
  },
  ONLINE: {
    name: '在线',
    value: 'ONLINE'
  },
  WAIT_TO_OFFLINE: {
    name: '等待离线',
    value: 'WAIT_TO_OFFLINE'
  }
};

export const DEPLOY_STATUS = {
  INSTALLED: 'INSTALLED',
  UNINSTALLED: 'UNINSTALLED'
};

export const DINGDING_STAGE_TYPE = {
  EXECUTE_TASK_NORMAL: '正常执行任务',
  EXECUTE_TASK_AGENT: '代理人执行任务',
  APPEND_TASK_BEFORE: '前加签任务',
  APPEND_TASK_AFTER: '后加签任务',
  REDIRECT_TASK: '转交任务',
  START_PROCESS_INSTANCE: '发起流程实例',
  TERMINATE_PROCESS_INSTANCE: '终止(撤销)流程实例',
  FINISH_PROCESS_INSTANCE: '结束流程实例',
  ADD_REMARK: '添加评论',
  redirect_process: '审批退回',
  PROCESS_CC: '抄送'
};

export const DINGDING_STAGE_RESULT = {
  AGREE: '同意',
  REFUSE: '拒绝',
  NONE: '无'
};

export const WHITE_LIST_ADD_TYPE = {
  全部: 'ADD_ALL',
  内网IP: 'PRIVATE_IP_ONLY',
  公网出口IP: 'PUBLIC_IP_ONLY'
};

export const SECURITY_TYPE = {
  KERBEROS: 'KERBEROS',
  USER_PASSWD_WITH_TLS: 'USER_PASSWD_WITH_TLS',
  USER_PASSWD: 'USER_PASSWD',
  NONE: 'NONE',
  ONLY_PASSWD: 'ONLY_PASSWD'
};

export const CONSOLE_TASK_STATE = {
  SUCCESS: '成功',
  WAIT_START: '等待开始',
  EXECUTE: '执行中',
  FAILED: '失败',
  CANCELED: '已取消',
  SKIP: '已忽略'
};

export const CONSOLE_JOB_NAME = {
  RDS_ADD_PUBLIC_NET: 'RDS开放公网',
  RDS_AUTO_ADD_ACCOUNT: 'RDS自动创建账号密码',
  INSTALL_ECS: 'ECS安装客户端',
  UPGRADE_ECS: 'ECS更新客户端',
  UNINSTALL_ECS: 'ECS卸载客户端',
  INSTALL_LOCAL_MAC: '自建机器安装客户端',
  UNINSTALL_LOCAL_MAC: '自建机器卸载客户端',
  RDS_ADD_IP_WHITE_LIST: 'RDS添加白名单',
  ALIYUN_ADD_WHITELIST_INFO: '阿里云添加白名单信息',
  START_ECS_CLIENT: 'ECS启动客户端'
};

export const RESOURCE_TYPE = {
  DATASOURCE: '数据源',
  WORKER: '机器'
};

export const MYSQL_DATA_TYPE = {
  INT: ['bigint', 'tinyint', 'smallint', 'mediumint', 'int'],
  BOOL: ['bool', 'boolean'],
  BIT: ['bit'],
  FLOAT: ['decimal', 'float', 'double'],
  TIME: ['date', 'datetime', 'timestamp', 'time', 'year'],
  CHAR: ['char', 'varchar'],
  BINARY: ['binary', 'varbinary', 'tinyblob', 'blob', 'mediumblob', 'longblob'],
  TEXT: ['tinytext', 'mediumtext', 'longtext', 'text'],
  ENUM: ['enum', 'set'],
  JSON: ['json']
};

export const ALGORITHM_TYPES = [
  {
    name: '无',
    value: 0
  }, {
    name: '全遮掩',
    value: 1
  }, {
    name: '固定位置遮掩',
    value: 2
  }, {
    name: '固定字符遮掩',
    value: 3
  }, {
    name: '映射替换',
    value: 4
  }, {
    name: '随机替换',
    value: 5
  }
];

export const ALGORITHM_TYPES_PLACEHOLDER = {
  2: 'format：(1, 4), (8, 10), (-4)',
  3: '目标字符',
  4: '目标字符',
  5: 'format：(1, 4), (8, 10), (-4)'
};

export const DB_TYPE = {
  name: 'NAME',
  type: 'TYPE',
  nullable: 'NULLABLE',
  length: 'LENGTH',
  numericPrecision: 'NUMERIC_PRECISION',
  numericScale: 'NUMERIC_SCALE',
  datetimePrecision: 'DATETIME_PRECISION',
  defaultValue: 'DEFAULT',
  autoGenerate: 'AUTO_GENERATE',
  comment: 'COMMENT'
};

export const PG_GP = ['PostgreSQL', 'Greenplum'];

export const BIZ_TYPE = {
  TICKETS_WORKFLOW: 'TICKETS_WORKFLOW',
  QUERY_CONSOLE: 'QUERY_CONSOLE'
};

export const BIZ_TYPE_I18N = {
  TICKETS_WORKFLOW: '工单',
  QUERY_CONSOLE: '控制台'
};

export const EXPORT_STATUS = {
  INIT: 'INIT',
  WAIT_TO_START: ' WAIT_TO_START',
  RUNNING: 'RUNNING',
  WAIT_TO_CANCEL: 'WAIT_TO_CANCEL',
  CANCEL: 'CANCEL',
  WAIT_TO_FAIL: 'WAIT_TO_FAIL',
  FAIL: 'FAIL',
  COMPLETE: 'COMPLETE'
};

export const EXPORT_STATUS_I18N = {
  INIT: '初始化',
  WAIT_TO_START: '等待开始',
  RUNNING: '运行中',
  WAIT_TO_CANCEL: '等待取消',
  CANCEL: '已取消',
  WAIT_TO_FAIL: '失败',
  FAIL: '失败',
  COMPLETE: '完成'
};

export const PARAMS_CONFIG = {
  ds: {
    get: 'getDsConfig',
    edit: 'updateDsConfig'
  },
  user: {
    get: 'getCurrUserConfigs',
    edit: 'updateUserConfigs'
  }
};

export const RESULT_TYPE = {
  LOCAL_FILE: '本地文件地址'
};

export const SUPPORT_RULE_DATASOURCE_TYPE = ['MySQL'];
