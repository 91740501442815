import {
  UPDATE_CLUSTER_LIST,
  UPDATE_DEPLOY_ENV_LIST_MAP,
  UPDATE_DS_TYPE_LIST,
  UPDATE_REGION_LIST_MAP,
  UPDATE_USERINFO
} from '@/store/mutationTypes';
import request from '@/services/http/request';
import { api } from '@/services/http/api';
import { CLUSTER_ENV } from '@/consts';

export default {
  async getUserInfo({
    commit
  }) {
    const res = await request({ url: api.getUserInfo });
    const res2 = await request({ url: api.listUserAuth });
    const res3 = await request({ url: api.getGlobalSettings });

    if (res.success && res2.success && res3.success) {
      commit(UPDATE_USERINFO, {
        ...res.data,
        auth: res2.data,
        global: res3.data
      });
      // if (res.data && !res.data.opCodeSet) {
      //   window.$bus.emit('setOpPasswordModal', res.data.opCodeSet);
      // }
    }
  },
  async getDeployEnvList({
    commit
  }) {
    const res = await request({ url: api.getDeployEnvList });
    if (res.success) {
      commit(UPDATE_DEPLOY_ENV_LIST_MAP, res.data);
    }
  },
  async getRegionList({
    commit
  }) {
    const res = await request({
      url: api.getRegionList,
      data: { deployEnvType: CLUSTER_ENV.ALIBABA_CLOUD_HOSTED }
    });
    const res2 = await request({
      url: api.getRegionList,
      data: { deployEnvType: CLUSTER_ENV.SELF_MAINTENANCE }
    });

    if (res.success && res2.success) {
      const data = {
        aliyun: res.data,
        self: res2.data
      };
      commit(UPDATE_REGION_LIST_MAP, data);
    }
  },
  async getClusterList({
    commit
  }, deployEnvType) {
    const res = await request({
      url: api.getAllClusterList,
      data: { deployEnvType }
    });
    if (res.success) {
      commit(UPDATE_CLUSTER_LIST, res.data);
    }
  },
  async getDsTypeList({
    commit
  }, deployEnvType) {
    const res = await request({
      url: api.getDsTypeList,
      data: { deployEnvType }
    });

    if (res.success) {
      commit(UPDATE_DS_TYPE_LIST, res.data);
    }
  }
};

export const ACTIONS_TYPE = {
  GET_USER_INFO: 'getUserInfo',
  GET_DEPLOY_ENV_LIST: 'getDeployEnvList',
  GET_REGION_LIST: 'getRegionList',
  GET_CLUSTER_LIST: 'getClusterList',
  GET_DS_TYPE_LIST: 'getDsTypeList',
  GET_DS_LIST: 'getDsList'
};
