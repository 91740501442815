import dayjs from 'dayjs';
import moment from 'moment';

const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const randomString = (length) => {
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const arrayToObj = (arr, name) => {
  const temp = {};

  if (!arr.length) {
    return temp;
  }

  for (const role of arr) {
    if (!role[name]) {
      return {};
    }
    temp[role[name]] = role;
  }
  return temp;
};

export const formatTime = (value, fmt = 'YYYY/MM/DD') => dayjs(value)
  .format(fmt);

export const generateData = (list) => list;

export const dsGroup = {
  hasSchema: ['PostgreSQL', 'Greenplum'],
  noStruct: ['Redis'],
  supportTransaction: ['MySQL', 'PostgreSQL', 'Greenplum']
};

export const hasSchema = (type) => dsGroup.hasSchema.includes(type);

export const noStruct = (type) => dsGroup.noStruct.includes(type);

export const supportTransaction = (type) => dsGroup.supportTransaction.includes(type);

export const dateRange = {
  一天: [moment(), moment().add(1, 'd')],
  一周: [moment(), moment().add(1, 'w')],
  一月: [moment(), moment().add(1, 'M')]
};
