<template>
  <div class="schema-tree-select">
    <div class="search">
      <a-input-search placeholder="请输入实例ID/名称查询" size="small" @change="onChange"/>
    </div>
    <div class="tree">
      <a-tree :tree-data="tree" @select="handleSelectNode" :expanded-keys.sync="expandedKeys" :loaded-keys="loadedKeys"
              :load-data="handleGetSchema" show-icon>
        <template slot="dataSourceType" slot-scope="record">
          <cc-data-source-icon :instanceType="record.deployEnvType" :size="18" :type="record.dataSourceType"
                               color="#4DBAEE"/>
        </template>
        <template slot="schemaType">
          <cc-iconfont name="shujuku" color="#999999" :size="12"/>
        </template>
      </a-tree>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
// import Vue from 'vue';
import { PG_GP } from '../../consts';

const TAG = 'schema_tree_select';

export default {
  name: 'CCSchemaTreeSelect',
  props: {
    handleDataProcessing: Function,
    auth: Boolean
  },
  data() {
    return {
      preRequestData: {},
      tree: [],
      showTree: [],
      treeMap: {},
      expandedKeys: [],
      loadedKeys: [],
      // autoExpandParent: true,
      searchValue: '',
      pageSize: 20
      // moreDataObj: {}
    };
  },
  methods: {
    onChange(e) {
      const block = 'display: block';
      const none = 'display: none';
      const value = e.target.value;
      // const expandedKeys = [];
      this.tree.forEach((leaf) => {
        leaf.style = block;

        if (leaf.children) {
          leaf.children.forEach((leaf2) => {
            leaf2.style = block;

            if (leaf2.children) {
              leaf2.children.forEach((leaf3) => {
                leaf3.style = block;
              });
            }
          });
        }
      });
      this.tree.forEach((leaf) => {
        let leafStyle = none;
        if (leaf.title.includes(value)) {
          leafStyle = block;
        } else {
          leafStyle = none;
          if (leaf.children) {
            leaf.children.forEach((leaf2) => {
              let leaf2Style = none;
              if (leaf2.title.includes(value)) {
                leaf2Style = block;
              } else {
                leaf2Style = none;
                if (leaf2.children) {
                  leaf2.children.forEach((leaf3) => {
                    let leaf3Style = none;
                    if (leaf3.title.includes(value)) {
                      leaf3Style = block;
                    } else {
                      leaf3Style = none;
                    }

                    if (leaf3Style === block) {
                      leaf2Style = block;
                    }

                    leaf3.style = leaf3Style;
                  });
                }
              }

              if (leaf2Style === block) {
                leafStyle = block;
              }

              // if (leaf2Style === block) {
              //   expandedKeys.push(leaf2.key);
              // }
              leaf2.style = leaf2Style;
            });
          }
        }
        //
        // if (leafStyle === block) {
        //   expandedKeys.push(leaf.key);
        // }
        leaf.style = leafStyle;
      });

      this.tree = [...this.tree];
      // if (expandedKeys.length) {
      //   this.autoExpandParent = true;
      //   this.expandedKeys = expandedKeys;
      // }
    },
    handleGetSchema(treeNode) {
      this.loadedKeys.push(treeNode.dataRef.key);
      return new Promise((resolve) => {
        if (!treeNode.dataRef.isLeaf && !treeNode.dataRef.isRoot) {
          if (treeNode.dataRef.deep === 3) {
            this.getSchemaList(treeNode.dataRef, treeNode);
          } else {
            this.getLeafData(treeNode.dataRef, treeNode);
          }
          resolve();
        } else {
          resolve();
        }
      });
    },
    async getDsList(data = {}) {
      const res = await this.$services.getDsList({ data: { ...data, useVisibility: true } });
      const tree = {};
      const treeMap = {};
      if (res.success) {
        res.data.forEach((ds) => {
          const {
            dsEnvName,
            dsEnvId,
            instanceDesc,
            dataSourceType
          } = ds;
          const key = `${dsEnvName}/${instanceDesc}`;
          const leaf1 = {
            title: dsEnvName,
            isRoot: true,
            deep: 1,
            key: dsEnvName,
            checkable: false,
            isLeaf: false
          };
          const leaf2 = {
            ...ds,
            isRoot: false,
            deep: 2,
            title: instanceDesc,
            scopedSlots: { icon: 'dataSourceType' },
            isLeaf: !PG_GP.includes(dataSourceType),
            key
          };
          treeMap[key] = leaf2;
          if (tree[dsEnvId]) {
            tree[dsEnvId].children.push(leaf2);
          } else {
            treeMap[leaf1.key] = leaf1;
            tree[dsEnvId] = {
              ...leaf1,
              children: [leaf2]
            };
          }
        });
      }
      this.tree = Object.values(tree);
      this.treeMap = cloneDeep(treeMap);
    },
    handleSelectNode(selectedKeys) {
      let treeNode = {};
      if (selectedKeys.length) {
        const key = selectedKeys[0];
        treeNode = this.treeMap[key];
        this.selectedTreeNode = treeNode;
      } else {
        treeNode = this.selectedTreeNode;
      }

      if (treeNode && treeNode.isLeaf) {
        if (treeNode.deep === 2) {
          this.getLeafData(treeNode);
        } else {
          this.getSchemaList(treeNode);
        }
        this.selectedTreeNode = {};
      }
    },
    async getTables(schema) {
      const {
        id: dataSourceId,
        title: schemaName,
        parentData = ''
      } = schema;
      const res = await this.$services.listDbTables({
        data: {
          dataSourceId,
          schemaName,
          parentData,
          useVisibility: true
        }
      });

      if (res.success) {
        this.handleDataProcessing(res.data, schema);
      }
    },
    sliceData(key) {
      console.log(key);
    },
    async getLeafData(datasource, treeNode = null) {
      const cloneDs = cloneDeep(datasource);
      delete cloneDs.scopedSlots;
      if (treeNode) {
        treeNode.dataRef.loading = true;
      }

      const res = await this.$services.listSchemaFirstLevel({
        data: {
          dataSourceId: datasource.id,
          useVisibility: true
        },
        page: 'schema_tree_select'
      });

      if (treeNode) {
        treeNode.dataRef.loading = false;
      }

      if (res.success && res.data.nameList && res.data.nameList.length) {
        const {
          nameList
        } = res.data;
        if (treeNode) {
          treeNode.dataRef.children = [];
          const {
            dsEnvName,
            instanceDesc
          } = datasource;
          nameList.forEach((schema) => {
            const key = `${dsEnvName}/${instanceDesc}/${schema}`;
            const leaf = {
              ...cloneDs,
              deep: 3,
              scopedSlots: { icon: 'schemaType' },
              isisRoot: false,
              title: schema,
              isLeaf: true,
              key
            };
            this.treeMap[key] = leaf;
            treeNode.dataRef.children.push(leaf);
          });
          this.tree = [...this.tree];
          // Vue.set(this.moreDataObj, treeNode.dataRef.key, treeNode.dataRef.children);
          this.sliceData(treeNode.dataRef.key);
        } else {
          this.handleDataProcessing(nameList, datasource);
        }
      } else {
        this.preRequestData = {
          datasource,
          treeNode
        };

        if (treeNode && treeNode.dataRef) {
          this.expandedKeys = this.expandedKeys.filter((key) => key !== treeNode.dataRef.key);
          this.loadedKeys = this.loadedKeys.filter((key) => key !== treeNode.dataRef.key);
        }
      }
    },
    async getSchemaList(datasource) {
      const keyArr = datasource.key.split('/');
      const res = await this.$services.listDsSchema({
        data: {
          dataSourceId: datasource.id,
          useVisibility: true,
          parentData: keyArr[keyArr.length - 1]
        }
      });

      if (res.success && res.data.length) {
        const data = res.data.map((s) => s.name);
        this.handleDataProcessing(data, datasource);
      } else {
        this.preRequestData = {
          datasource
        };
      }
    },
    handlePreRequest() {
      const {
        datasource,
        treeNode
      } = this.preRequestData;
      this.getLeafData(datasource, treeNode);
      if (treeNode && treeNode.dataRef) {
        this.expandedKeys.push(treeNode.dataRef.key);
      }
    }
  },
  created() {
    this.getDsList();
    window.$bus.on(`${TAG}_listfirstlevel_callback`, this.handlePreRequest);
  },
  destroyed() {
    window.$bus.off(`${TAG}_listfirstlevel_callback`);
  }
};
</script>

<style scoped lang="less">
.schema-tree-select {
  height: calc(~"100vh - 160px");
  width: 320px;
  border: 1px solid rgba(199, 199, 199, 1);
  overflow: scroll;

  .search {
    height: 44px;
    width: 100%;
    line-height: 44px;
    padding: 0 10px;
    border-bottom: 1px solid #EAEAEA;
    background: #FAFAFA;
  }
}
</style>
