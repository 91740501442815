export const schemaApi = {
  listSchemaFirstLevel: '/datasource/schema/listfirstlevel',
  listDsSchema: '/datasource/schema/listschemas',
  listDbTables: '/datasource/schema/listtables',
  listTabColumns: '/datasource/schema/listcolumns',
  getDbTable: '/datasource/schema/getDbTable',
  listTableIndex: '/datasource/schema/listtableindex',
  listTableFk: '/datasource/schema/listtablefk',
  requestScript: '/datasource/schema/requestscript',
  groupTables: '/datasource/schema/grouptables',
  schemaEditorInit: '/datasource/schema/schemaeditorinit',
  schemaEditorApply: '/datasource/schema/schemaeditorapply',
  schemaEditorBuildDDL: '/datasource/schema/schemaeditorbuildddl',
  schemaEditorTypes: '/datasource/schema/schemaeditortypes',
  groupDsNodes: '/datasource/schema/groupdsnodes'
};
