export default {
  userInfo: {
    username: '',
    menuItems: [],
    auth: [],
    authArr: []
  },
  dsTypeList: {},
  clusterListMap: {},
  allClusterWorkers: 0,
  allClusterRunningWorkers: 0,
  deployEnvListMap: {},
  aliyunRegionListMap: {},
  selfRegionListMap: {},
  regionList: []
};
