<template>
  <div class="cc-status">
    <div :style="styleVar" class="type"/>
    <div class="content">{{content}}</div>
  </div>
</template>

<script>
import { STATUS_COLOR } from '@/consts';

export default {
  name: 'CCStatus',
  props: {
    type: {
      type: String,
      default: 'success'
    },
    content: {
      type: String,
      default: ''
    },
    color: String
  },
  computed: {
    styleVar() {
      return {
        '--status-color': this.color || STATUS_COLOR[this.type]
      };
    }
  }
};
</script>

<style lang="less" scoped>
.cc-status {
  display: flex;
  align-items: center;
  .type {
    .circle(8px, #52C41A);
    background: var(--status-color);
    margin-right: 6px;
  }
}
</style>
