export const commonApi = {
  getDeployEnvList: '/constant/listdeployenv',
  getRegionList: '/constant/listregionbydeploy',
  getDsTypeList: '/constant/listdstypes',
  getDsTypeLisrWithoutDeployEnv: '/constant/listdstypeswithoutenv',
  getDesensitizeRuleTypeList: '/constant/listdesensitizeruletypes',
  getDsDesensitizePathElements: '/constant/listdsdesensitizepathelements',
  listPageElementsLevel: '/constant/listPageElementsLevel',
  listDsSecurityOption: '/constant/listdssecurityoption'
};
