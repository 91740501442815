export const userApi = {
  sendCode: '/verify/sendcode',
  sendCodeInLoginState: '/verify/sendcodeinloginstate',
  register: '/register',
  login: '/login',
  logout: '/logout',
  getGlobalSettings: '/global_settings',
  watermark: '/user/watermark',
  listOrg: '/list_org',
  loginSupplement: '/login_supplement',
  loadSupplementInfo: '/load_supplement_info',
  checkSupplement: '/check_supplement',
  getUserInfo: '/user/queryloginuser',
  resetPassword: '/user/resetpasswd',
  getSubAccountList: '/user/listsubaccounts',
  addSubAccount: '/user/addsubaccount',
  deleteSubAccount: '/user/deletesubaccount',
  updateUserRole: '/user/updateuserrole',
  stopSubAccount: '/user/updateaccountability',
  checkSubAccountDuplicate: 'user/checksubaccountduplicate',
  getResourceSummary: '/user/resourcesummary',
  resetOpPwd: '/user/resetoppasswd',
  getDbOpAudits: '/user/dbopaudits', // 数据源操作记录
  updatealiyunaksk: '/user/updatealiyunaksk',
  verifyOpPwd: '/user/oppasswdverify',
  cleanaliyunaksk: '/user/cleanaliyunaksk',
  listUserAuth: '/user/listuserauth',
  getCurrUserConfigs: '/user/config/getcurruserconfigs',
  updateUserConfigs: '/user/config/updateuserconfigs'
};
